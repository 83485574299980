import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Switch, Route, Redirect} from 'react-router-dom';
import { setNewState } from '../redux/userSlice';

import { AuthorizationPage } from './pages/authorizationPage';
import { HomePage } from './pages/homePage';
import { TestPage } from './pages/testPage';
import { ReviewPage } from './pages/reviewPage';
import ResetPasswordPage from "./pages/resetPasswordPage";

export const useRoutes = () => {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem('userData'));

  if (userData) {
    dispatch(setNewState(userData));
  }

  const userStateToken = useSelector(state => state.userData.token);

  if (userStateToken === null || userStateToken === undefined) {
    return (
      <Switch>
        <Route exact path="/authorization">
          <AuthorizationPage />
        </Route>
        <Route path='/forgot-password-reset/:token'>
          <ResetPasswordPage />
        </Route>
        <Redirect to="/authorization" />
      </Switch>
    )
  }

  return (
    <Switch>
      <Route exact path="/">
        <HomePage />
      </Route>
      <Route path='/test/:id'>
        <TestPage />
      </Route>
      <Route path='/review/:id'>
        <ReviewPage />
      </Route>
      <Redirect to="/" />
    </Switch>
  )
}