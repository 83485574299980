import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {apiGetStates, apiUserSaveState} from "../../../api/apiCommands";
import {hideChoiceStateModalAction} from "../../../redux/modalSlice";
import {changeStateId, setNewState} from "../../../redux/userSlice";

const ChoiceStateModal = () => {
  const dispatch = useDispatch();
  const {token} = useSelector(state => state.userData);
  const [errors, setErrors] = useState({});
  const [selectedState, setSelectedState] = useState('');
  const [states, setStates] = useState([]);

  const loadStates = () => {
    apiGetStates(token)
      .then((response) => {
        if (response.status) {
          const {states} = response;
          setStates(states);
        }
      })
      .catch((error) => {
      });
  }

  useEffect(() => {
    loadStates();
  }, [])

  useEffect( () => {
    if (states) {
      console.log(states);
      setSelectedState(states[0]?.id);
    }
  }, [states]);

  const onChangeSelectedState = (event) => {
    const value = +event.target.value;
    setSelectedState(value);
  }

  const handleSubmit = () => {
    setErrors({});

    apiUserSaveState(token, selectedState)
      .then((response) => {
        if ('success' === response.status) {
          const {state_id} = response.user;

          const userData = JSON.parse(localStorage.getItem('userData'));

          localStorage.setItem('userData', JSON.stringify({
            ...userData,
            state_id
          }));

          dispatch(changeStateId({state_id}));
        } else {
          let responseErrors = {};
          for (const errorsKey in response.errors) {
            const errorMessage = response.errors[errorsKey];
            const newErrorKey = errorsKey.replace('[', '').replace(']', '');
            responseErrors[newErrorKey] = errorMessage;
          }

          setErrors(responseErrors);
        }
      })
      .catch((error) => {
      });
  }

  const isValidForm = () => {
    return Object.keys(errors).length === 0;
  }

  return (
    <div className="choice-state-modal">
      <div className="choice-state-modal-content">

        <div className="choice-state-modal-content-item">
          <div className="heading">
            <h4>Please set your state.</h4>
          </div>
        </div>

        <div className="choice-state-modal-content-item">
          <select name="state_id" id="state" onChange={onChangeSelectedState} defaultValue={selectedState}>
            { states.map(state => (
              <option key={state.id + 'state'} value={state.id}>{state.name}</option>
            ))}
          </select>
        </div>

        {! isValidForm() &&
          <div className="choice-state-modal-content-item">
            <div className="errors">
              {Object.values(errors).map((error, index) => (
                <div key={index} className="choice-state-modal-content-error">{error}</div>
              ))}
            </div>
          </div>
        }

        <div className="choice-state-modal-content-item">
          <button type="button" onClick={handleSubmit} className="btn btn-submit">Set state</button>
        </div>
      </div>
    </div>
  );
}

export default ChoiceStateModal;